import React, { Fragment } from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';

import SEO from '../components/SEO';
import {
  Main,
  Section,
  Container,
  Btn,
  LinkBtn,
  LinkBtnRed,
} from '../components/Elements';
import Header from '../components/Header';
import FooterPre from '../components/FooterPre';

import { Hero } from '../components/HeroPages';
import Form from '../components/ContactLong';
import { FourPoints } from '../components/FourPoints';
import { Heading, Flex, Box, Text, Image } from 'rebass';

import { TimeTable } from '../components/TimeTable';

import _map from 'lodash/map';

/* Todo: https://github.com/datocms/gatsby-source-datocms#modular-content-fields */

const Sijainti = () => {
  const data = useStaticQuery(sijaintiQuery);
  const {
    seoMetaTags,
    content,
    heroLayer,
    imgHero,
    lead,
    title,
    autollaIkoni,
    autollaOtsikko,
    autollaTaulukko,
    junallaIkoni,
    junallaOtsikko,
    junallaTaulukko,
    kuva2,
    otsikko2,
    otsikko3,
    pyorallaIkoni,
    pyorallaOtsikko,
    pyorallaTaulukko,
    teksti2,
    yhteydetOtsikko,
    yhteydetAlateksti,
    yhteydetAlateksti2,
    vilkaisuja,
  } = data.datoCmsSijainti;
  const { tausta2, tausta3 } = data.datoCmsSivusto;

  const introLayer = 'rgba(223, 208, 183, 0.8)';

  return (
    <Fragment>
      <SEO meta={seoMetaTags} />
      <Header />
      <Main bg={'beigeBg'} color={'blueDark'} textAlign={'center'} pb={6}>
        <Hero
          title={title}
          lead={lead}
          imgHero={imgHero}
          introLayer={introLayer}
          heroLayer={heroLayer}
          introColor={'blueDark'}
        />
        <Section bg={'beigeBg'} color={'blueDark'}>
          <Container flexDirection="column">
            <Text
              mx={'auto'}
              py={5}
              maxWidth={720}
              dangerouslySetInnerHTML={{
                __html: data.datoCmsSijainti.content,
              }}
            />
            <Heading fontSize={6} sx={{ zIndex: 1 }}>
              {yhteydetOtsikko}
            </Heading>
          </Container>

          <Container className="kulkuvalineet" flexWrap="wrap">
            <TimeTable
              icon={junallaIkoni}
              header={junallaOtsikko}
              table={junallaTaulukko}
            />
            <TimeTable
              icon={autollaIkoni}
              header={autollaOtsikko}
              table={autollaTaulukko}
            />
            <TimeTable
              icon={pyorallaIkoni}
              header={pyorallaOtsikko}
              table={pyorallaTaulukko}
            />
          </Container>
          <Container flexDirection="column">
            <Text
              fontSize={1}
              dangerouslySetInnerHTML={{
                __html: yhteydetAlateksti,
              }}
            />
            <Text
              pb={[0, 2]}
              fontSize={3}
              fontWeight={300}
              dangerouslySetInnerHTML={{
                __html: yhteydetAlateksti2,
              }}
            />
          </Container>

          <Container flexWrap="wrap">
            <Flex
              width={[1, 1 / 2]}
              my={4}
              justifyContent="center"
              flexDirection="column"
              textAlign="left"
            >
              <Heading fontSize={6} maxWidth={400}>
                {otsikko2}
              </Heading>
              <Text my={2} maxWidth={445}>
                {teksti2}
              </Text>
            </Flex>
            <Flex width={[1, 1 / 2]} flexWrap="wrap" justifyContent="center">
              <Box width={'100%'} maxWidth={'536px'}>
                <Img fluid={kuva2.fluid} alt={kuva2.alt} width={536} />
              </Box>
            </Flex>
          </Container>
          <Container justifyContent="center">
            <Heading mt={6} fontSize={6} sx={{ zIndex: 1 }}>
              {otsikko3}
            </Heading>
          </Container>
          <Container flexWrap="wrap" sx={{ zIndex: 1 }}>
            {_map(vilkaisuja, vilkaisuja => (
              <Box
                key={vilkaisuja.id}
                pt={4}
                pb={[1, 3]}
                px={2}
                width={[1, 1 / 5]}
                sx={{ zIndex: 1 }}
              >
                <Box height={60}>
                  <Image src={vilkaisuja.icon.url} width={40} />
                </Box>

                <Text
                  fontSize={0}
                  fontWeight={600}
                  letterSpacing={'2px'}
                  sx={{ textTransform: 'uppercase' }}
                >
                  {vilkaisuja.teksti}
                </Text>
              </Box>
            ))}
          </Container>
        </Section>
      </Main>
      <FooterPre />
    </Fragment>
  );
};

const sijaintiQuery = graphql`
  {
    datoCmsSivusto {
      tausta2 {
        url
      }
      tausta3 {
        url
      }
    }
    datoCmsSijainti {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      lead
      content
      heroLayer {
        alpha
        blue
        green
        red
      }
      imgHero {
        fluid(maxWidth: 1600, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      autollaIkoni {
        url
      }
      autollaOtsikko
      autollaTaulukko
      junallaIkoni {
        url
      }
      junallaOtsikko
      junallaTaulukko
      kuva2 {
        fluid(maxWidth: 536, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      otsikko2
      otsikko3
      pyorallaIkoni {
        url
      }
      pyorallaOtsikko
      pyorallaTaulukko
      teksti2
      yhteydetOtsikko
      yhteydetAlateksti
      yhteydetAlateksti2
      vilkaisuja {
        id
        icon {
          url
        }
        teksti
      }
    }
  }
`;

export default Sijainti;
