import React from 'react';
import { Heading, Text, Box, Flex, Image } from 'rebass';
import Img from 'gatsby-image';

export const TimeTable = ({ icon, header, table }) => (
  <Flex width={[1, 1, 1 / 3]} p={1} color={'white'}>
    <Flex
      maxWidth={'520px'}
      mx="auto"
      py={3}
      width={1}
      bg={'blueDarkBg'}
      flexDirection="column"
    >
      <Flex height="90px">
        <Image my={2} mx={'auto'} width={48} src={icon.url} />
      </Flex>
      <Text
        p={0}
        fontSize={'16px'}
        letterSpacing={'3px'}
        fontWeight={700}
        sx={{ textTransform: 'uppercase' }}
      >
        {header}
      </Text>
      <Text
        className={'timeTable'}
        py={2}
        px={4}
        fontSize={1}
        fontWeight={300}
        dangerouslySetInnerHTML={{
          __html: table,
        }}
      />
    </Flex>
  </Flex>
);
